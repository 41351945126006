import { spacing, fontSize } from "../styles/mixins";
import { css } from "@emotion/react";
import { colours } from "./colours";

export const abcMaxiPlusMono = css`
	font-family: "ABC Maxi Round Mono", "Helvetica", "Arial", sans-serif;
`;

export const fontSurt = css`
	font-family: "Surt", "Helvetica", "Arial", sans-serif;
	word-spacing: 0;
	letter-spacing: 0.01em;
	font-feature-settings: "salt" on;
`;

export const displayH1 = css`
	${fontSize(7)};
	${abcMaxiPlusMono};
	line-height: 0.9;
`;

export const displaySubtitle = css`
	${fontSize(1.5)};
	${fontSurt};
	line-height: 0.9;
`;

export const h1 = css`
	${fontSize(8.2)};
	${abcMaxiPlusMono};
	font-weight: 400;
	line-height: 0.9;
`;

export const h2 = css`
	${fontSize(6.5)};
	${abcMaxiPlusMono};
	line-height: 1.05;
	strong {
		${fontSurt};
		font-weight: normal;
	}
`;

export const h3 = css`
	${fontSize(5)};
	${abcMaxiPlusMono};
	line-height: 1.1;
`;

export const h4 = css`
	${fontSize(4)};
	${abcMaxiPlusMono};
	line-height: 1.1;
`;

export const h5 = css`
	${fontSize(1.1)};
	${abcMaxiPlusMono};
	line-height: 1.1;
`;

export const h6 = css`
	${fontSize(2)};
	${abcMaxiPlusMono};
	line-height: 0.9;
`;

export const outroTitle = css`
	${fontSize(6)};
	${abcMaxiPlusMono};
	line-height: 1.05;
	strong {
		${fontSurt};
		font-weight: normal;
	}
`;

export const pMega = css`
	${fontSize(4)};
	${abcMaxiPlusMono};
	line-height: 1.2;
`;

export const pEmphasis = css`
	${fontSize(2.5)};
	${fontSurt};
	line-height: 1.24;
`;

export const graphTitle = css`
	${fontSize(2)};
	${abcMaxiPlusMono};
	font-weight: 400;
	line-height: 1.15;
`;

export const pLead = css`
	${fontSize(1.5)};
	${abcMaxiPlusMono};
	line-height: 1.6;
	font-feature-settings: "ss05" on, "ss06" on;
`;

export const pStandard = css`
	${fontSize(0.5)};
	${fontSurt};
	line-height: 1.6;
`;

export const meta = css`
	${fontSize(-1)};
	${fontSurt};
	line-height: 1.12;
`;

export const metaHeading = css`
	${fontSize(-1)};
	${abcMaxiPlusMono};
	line-height: 1;
`;

export const display = css`
	${fontSize(10)};
	${abcMaxiPlusMono};
`;

export const formTitle = css`
	${fontSize(2)};
	${fontSurt};
`;

export const pSmall = css`
	${fontSize(-0.5)};
	${fontSurt};
	line-height: 1;
`;

export const embed = css`
	div[data-oembed] {
		padding-top: 56.25%;
		position: relative;

		iframe {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			height: 100%;
		}
	}
`;

export const richText = css`
	h1,
	.h1 {
		${h1};
		${spacing("marginTop", "xl")};
		${spacing("marginBottom", "l")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h2,
	.h2 {
		${h2};
		${spacing("marginTop", "xl")};
		${spacing("marginBottom", "l")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h3,
	.h3 {
		${h3};
		${spacing("marginTop", "l")};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h4,
	.h4 {
		${h4};
		${spacing("marginTop", "l")};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h5,
	.h5 {
		${h5};
		${spacing("marginTop", "l")};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h6,
	.h6 {
		${h6};
		${spacing("marginTop", "l")};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	p {
		${pStandard};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	p.lead {
		${pLead};
		${spacing("marginBottom", "l")};
	}

	.meta {
		${meta};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		+ h2,
		+ .h2 {
			margin-top: 0;
		}
	}

	.p-small {
		${pSmall};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		+ h2,
		+ .h2 {
			margin-top: 0;
		}
	}

	ul,
	ol {
		${pStandard}
	}
	ul {
		${spacing("marginBottom", "s")};
		li {
			${spacing("marginBottom", "s")};
			padding-left: 1.6em;
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				content: "//";
				position: absolute;
				top: 0;
				left: 0;

				vertical-align: middle;
				display: block;
				flex-grow: 0;
				flex-shrink: 0;
			}

			p {
				display: inline-block;
			}
		}
	}

	img {
		max-width: 100%;
	}

	${embed};

	a,
	button {
		border-bottom: 1px solid var(--link-underline);
		transition: border-color 400ms ease;

		&:hover {
			border-bottom: 1px solid var(--link-underline-hover);
			/* color: ${colours.highlight}; */
		}
	}

	button:focus {
		outline: none;
	}

	> :last-child {
		margin-bottom: 0;
	}
`;

export default pStandard;
